import Link from 'next/link';
import { ButtonWrapper, Btn, Txt } from './style';
import { Colors } from '../common';

export enum ButtonType {
  file,
  url,
  local,
}

interface IButton {
  label: string;
  type?: ButtonType;
  url: string;
  color: Colors;
  position?: string;
}

const Button = (props: IButton) => {
  return (
    <ButtonWrapper stxColor={props.color} floatPos={props.position}>
      <Link href={props.url} css={Btn} target={props.type === ButtonType.file || props.type === ButtonType.url ? '_blank' : '_self'}
          rel={props.type === ButtonType.file || props.type === ButtonType.url ? 'noopener noreferrer' : ''}>
          <Txt>{props.label}</Txt>
      </Link>
    </ButtonWrapper>
  );
};
export default Button;
