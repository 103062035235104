import React, {
  useEffect,
  useRef,
  useState,
  MouseEvent,
  createRef,
  RefObject,
  useContext,
} from 'react';
import Link from 'next/link';
import { MenuContext } from '../Layout';
import { ILink, IMenu } from '../common';
import { NavWrapper, Nav, SubNav, NavItem, Hamburger, Button, Expander, ThirdNav } from './menuStyle';
import { HandleType } from '../../lib/utilities';
import { ButtonType } from '../Button';
export interface IMenuProps {
  handleOverlay: React.Dispatch<React.SetStateAction<boolean>>;
  hideMenu: React.SetStateAction<boolean>;
}

export interface IExpanderProps {
  isActive: boolean;
}

export interface ISubMenuRefs {
  idx: number;
  refs: RefObject<HTMLAnchorElement>[];
}

export interface ISubMenuState {
  parentIdx: number;
  currentIdx: number;
  isActive: boolean;
}

const PathSeparator = '/';

const URLBuilder = (parentSlug?: string, currentSlug?: string): string => {
  let out = "";//publicRuntimeConfig.BASEURL;
  if (parentSlug !== undefined) {
    out += parentSlug + PathSeparator + currentSlug;
  } else {
    out += currentSlug;
  }
  return out;
};

export const Menu = ({ handleOverlay, hideMenu }: IMenuProps) => {
  const data = useContext(MenuContext);
  const HomeTitle = 'Home';
  const navRef = useRef([]);
  let subCounterIndex = -1;
  const [parentActive, setParentActive] = useState<number>(-1);
  const [showMenuParent, setShowMenuParent] = useState<boolean[]>([]);
  const [subActive, setSubActive] = useState<number>(-1);
  const [showMenuChild, setShowMenuChild] = useState<ISubMenuState[]>([]);
  const [showMenu, setShowMenu] = useState(false);
  const [showMenuIcon, setShowMenuIcon] = useState(false);
  const [realData, setRealData] = useState<IMenu[]>([]);
  const [currentWidth, setCurrentWidth] = useState<number>(0);

  let topTimer: NodeJS.Timeout;

  // removing the first item ( Home from the navigation)
  useEffect(() => {
    if (realData.length === 0) {
      let shal: IMenu[] = data;
      const newData = shal?.slice(1, shal.length).map(item => item);
      if(newData?.length >0){
        setRealData(newData);
        // add or remove top level refs
        navRef.current = Array(newData.length)
          .fill('')
          .map((_, i) => navRef.current[i] || createRef<HTMLAnchorElement>());
        }
      } else{
        setRealData(data);
      }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const TopStateBuilder = (data: IMenu[]) => {
    data.map((item, idx) => {
      if (item.title === HomeTitle) return;
      const shal = showMenuParent;
      shal.push(false);
      setShowMenuParent(shal);
    });
  };

  const SubStateBuilder = (data: IMenu[]) => {
    data.forEach((listItem, idx) => {
      if (listItem.submenu !== undefined && listItem.submenu.length > 0) {
        if (listItem.submenu === undefined) return;

        listItem?.submenu.forEach((sub, subIdx) => {
          if (sub.submenu === undefined || sub.submenu.length === 0) return;
          const shal = showMenuChild;
          shal.push({ parentIdx: idx, currentIdx: subIdx, isActive: false });
          setShowMenuChild(shal);
        });
      }
    });
  };

  useEffect(() => {
    TopStateBuilder(realData);
    SubStateBuilder(realData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realData]);

  const HandleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowMenu(!showMenu);
    if(!showMenu){
      CleanupMobileActive();
    }
  };

  const HasGrandChildren = (item: IMenu[]): boolean => {
    let hasGrand = false;
    item.forEach(child => {
      if (child.submenu && child.submenu.length > 0) {
        hasGrand = true;
      }
    });
    return hasGrand;
  };

  useEffect(() => {
    if (window.innerWidth <= 1023) {
      setShowMenuIcon(true);
      setShowMenu(false);
    }
    if (window.innerWidth > 1023) {
      setShowMenu(true);
    }
    setCurrentWidth(window.innerWidth);

    const handleResize = () => {
      setShowMenuIcon(window.innerWidth <= 1023);
      setShowMenu(window.innerWidth > 1023);
      setCurrentWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const CleanupActive = () => {
    if (showMenuIcon) {
      CleanupMobileActive();
    }

    navRef.current.map(item => {
      const el = item as unknown as RefObject<HTMLAnchorElement>;
      if (el !== null && el.current !== null) {
        const b = el.current as unknown as HTMLAnchorElement;
        b.classList.remove('active');
      }
      return;
    });
  };

  useEffect(() => {
    if (hideMenu) {
      CleanupActive();
      if (window.innerWidth <= 1023) {
        setShowMenu(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideMenu]);

  const CleanupMobileActive = () => {
    const s: boolean[] = Array(showMenuParent.length).fill(false);
    CleanupSubActive();
    setParentActive(-1);

    // not a big fan
    setTimeout(() => {
      setShowMenuParent(prev => s);
    }, 500);
  };

  const ToggleNavOn = (event: MouseEvent<HTMLAnchorElement>, index: number) => {
    if (!showMenuIcon) {
      clearTimeout(topTimer);
      CleanupActive();
      const target = event.target as HTMLAnchorElement;
      target.classList.add('active');
      handleOverlay(true);
    }
  };

  const ToggleMobileItem = (event: MouseEvent<HTMLButtonElement>, index: number) => {
    const status = !showMenuParent[index];
    CleanupMobileActive();
    if (status) {
      const shal = showMenuParent;
      shal[index] = status;
      setParentActive(index);
      setShowMenuParent(shal);
    }
  };

  const CleanupSubActive = () => {
    showMenuChild.map(item => (item.isActive = false));
    setShowMenuChild(showMenuChild);
    setSubActive(-1);
  };

  const ToggleMobileSubItem = (event: MouseEvent<HTMLButtonElement>, parentIndex: number, index: number) => {
    CleanupSubActive();

    const targetIndex = GetCurrentTarget(parentIndex, index);
    showMenuChild[targetIndex].isActive = !showMenuChild[targetIndex].isActive;
    setShowMenuChild(showMenuChild);
    if (showMenuChild[targetIndex].isActive === true) {
      setSubActive(targetIndex);
    }
  };

  const GetCurrentTarget = (parentIndex: number, index: number): number => {
    const targetIndex = showMenuChild.findIndex(item => item.parentIdx === parentIndex && item.currentIdx === index);
    return targetIndex;
  };

  const GenerateUniqueSubIndex = (parentIndex: number, index: number): number => {
    const targetIndex = showMenuChild.findIndex(item => item.parentIdx === parentIndex && item.currentIdx === index);
    if (targetIndex > -1) {
      subCounterIndex += 1;
    }
    return subCounterIndex;
  };

  const HandleClick = () => {
    if (currentWidth < 1024) {
      setShowMenu(!showMenu);
    }
  };

  const getRandomInt =(max:number = 99999):number =>{
    return Math.floor(Math.random() * max);
  }

  return (
    <>
      {showMenuIcon && <Hamburger aria-label="Menu" onClick={HandleMenuClick} />}
      {showMenu && (
        <NavWrapper>
          <Nav>
            {realData.map((value, index, item: IMenu[]) => {
              const { title, id, slug, submenu } = value;
              const parentSlug = slug;
              if (title === HomeTitle) {
                return;
              }
              let topNav: string = '';
              if(slug !== undefined){
                topNav = slug;
              }

              return (
                <NavItem
                  className={submenu && HasGrandChildren(submenu) ? 'with-grandkids' : ''}
                  key={`parennavitem_${id}`}>
                  <Link href={topNav} 
                        css={Button} 
                        className={index === parentActive ? 'active' : ''}
                        ref={navRef.current[index] as unknown as RefObject<HTMLAnchorElement>}
                        onMouseEnter={event => ToggleNavOn(event, index)}>
                      {title}
                  </Link>
                  {showMenuIcon && (
                    <Expander isActive={index === parentActive} onClick={event => ToggleMobileItem(event, index)} />
                  )}
                  {submenu && submenu.length > 0 && (
                    <SubNav>
                      {parentSlug !== undefined && submenu.map((menu, subidx) => {
                        const { slug, id, submenu, title } = menu;
                        const sIdex = GenerateUniqueSubIndex(index, subidx);
                        const childSlug = slug;
                        let btnType:ButtonType = ButtonType.local;
                        let midNav:string = "";
                        if(childSlug!== undefined){
                            const link:ILink = {url:childSlug,id:getRandomInt()} as ILink; 
                            btnType = HandleType(link);

                            if(btnType ===  ButtonType.local){
                              midNav= URLBuilder(undefined, childSlug);
                            } else {
                              midNav = childSlug;
                            }
                        }

                        return (
                          <NavItem key={`navitem_${id}`}>
                            <Link href={midNav} css={Button} onClick={HandleClick} className={sIdex === subActive ? 'active' : ''}>
                                {title}
                            </Link>

                            {submenu && submenu.length > 0 && (
                              <>
                                {showMenuIcon && (
                                  <Expander
                                    isActive={sIdex === subActive}
                                    onClick={event => ToggleMobileSubItem(event, index, subidx)}
                                  />
                                )}
                                <ThirdNav>
                                  {childSlug !== undefined && submenu.map(sub => {
                                    const { slug, id, title } = sub;
                                    const gNav = URLBuilder( childSlug , slug);

                                    return (
                                      <NavItem key={`subnavitem_${id}`}>
                                        <Link href={gNav} css={Button} onClick={HandleClick}>
                                          {title}
                                        </Link>
                                      </NavItem>
                                    );
                                  })}
                                </ThirdNav>
                              </>
                            )}
                          </NavItem>
                        );
                      })}
                    </SubNav>
                  )}
                </NavItem>
              );
            })}
          </Nav>
        </NavWrapper>
      )}
    </>
  );
};
